export enum types {
    AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST',
    AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
    AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE',

    GET_OPERATIONS_REQUEST = 'GET_OPERATIONS_REQUEST',
    GET_OPERATIONS_SUCCESS = 'GET_OPERATIONS_SUCCESS',
    GET_OPERATIONS_FAILURE = 'GET_OPERATIONS_FAILURE',

    GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST',
    GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS',
    GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE',

    GET_ACCOUNT_BALANCE_REQUEST = 'GET_ACCOUNT_BALANCE_REQUEST',
    GET_ACCOUNT_BALANCE_SUCCESS = 'GET_ACCOUNT_BALANCE_SUCCESS',
    GET_ACCOUNT_BALANCE_FAILURE = 'GET_ACCOUNT_BALANCE_FAILURE',

    GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST',
    GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS',
    GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE',

    CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST',
    CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS',
    CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE',

    UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST',
    UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS',
    UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE',

    DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST',
    DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS',
    DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE',

    GET_TAGS_REQUEST = 'GET_TAGS_REQUEST',
    GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS',
    GET_TAGS_FAILURE = 'GET_TAGS_FAILURE',

    CREATE_OPERATION_REQUEST = 'CREATE_OPERATION_REQUEST',
    CREATE_OPERATION_SUCCESS = 'CREATE_OPERATION_SUCCESS',
    CREATE_OPERATION_FAILURE = 'CREATE_OPERATION_FAILURE',

    UPDATE_OPERATION_REQUEST = 'UPDATE_OPERATION_REQUEST',
    UPDATE_OPERATION_SUCCESS = 'UPDATE_OPERATION_SUCCESS',
    UPDATE_OPERATION_FAILURE = 'UPDATE_OPERATION_FAILURE',

    REVERT_OPERATION_REQUEST = 'REVERT_OPERATION_REQUEST',
    REVERT_OPERATION_SUCCESS = 'REVERT_OPERATION_SUCCESS',
    REVERT_OPERATION_FAILURE = 'REVERT_OPERATION_FAILURE',

    DELETE_OPERATION_REQUEST = 'DELETE_OPERATION_REQUEST',
    DELETE_OPERATION_SUCCESS = 'DELETE_OPERATION_SUCCESS',
    DELETE_OPERATION_FAILURE = 'DELETE_OPERATION_FAILURE',

    POST_OPERATIONS_REQUEST = 'POST_OPERATIONS_REQUEST',
    POST_OPERATIONS_SUCCESS = 'POST_OPERATIONS_SUCCESS',
    POST_OPERATIONS_FAILURE = 'POST_OPERATIONS_FAILURE',

    GET_RECENTLY_POSTED_REQUEST = 'GET_RECENTLY_POSTED_REQUEST',
    GET_RECENTLY_POSTED_SUCCESS = 'GET_RECENTLY_POSTED_SUCCESS',
    GET_RECENTLY_POSTED_FAILURE = 'GET_RECENTLY_POSTED_FAILURE',

    GET_REPORT_RECORDS_REQUEST = 'GET_REPORT_RECORDS_REQUEST',
    GET_REPORT_RECORDS_SUCCESS = 'GET_REPORT_RECORDS_SUCCESS',
    GET_REPORT_RECORDS_FAILURE = 'GET_REPORT_RECORDS_FAILURE',

    GET_CURRENCY_RATES_REQUEST = 'GET_CURRENCY_RATES_REQUEST',
    GET_CURRENCY_RATES_SUCCESS = 'GET_CURRENCY_RATES_SUCCESS',
    GET_CURRENCY_RATES_FAILURE = 'GET_CURRENCY_RATES_FAILURE',

    CREATE_CURRENCY_RATE_REQUEST = 'CREATE_CURRENCY_RATE_REQUEST',
    CREATE_CURRENCY_RATE_SUCCESS = 'CREATE_CURRENCY_RATE_SUCCESS',
    CREATE_CURRENCY_RATE_FAILURE = 'CREATE_CURRENCY_RATE_FAILURE',

    GENERATE_EXPENSES_REPORT_REQUEST = 'GENERATE_EXPENSES_REPORT_REQUEST',
    GENERATE_EXPENSES_REPORT_SUCCESS = 'GENERATE_EXPENSES_REPORT_SUCCESS',
    GENERATE_EXPENSES_REPORT_FAILURE = 'GENERATE_EXPENSES_REPORT_FAILURE',
    REPORT_PERIOD_EXCEEDED = 'REPORT_PERIOD_EXCEEDED',

    REGISTRATION_REQUEST = 'REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
    REGISTRATION_FAILURE = 'REGISTRATION_FAILURE',

    LOG_OUT_REQUEST = 'LOG_OUT_REQUEST',
    LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
    LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',

    BEGIN_API_CALL = "BEGIN_API_CALL",
    API_CALL_ERROR = "API_CALL_ERROR",
}
