import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFunctions} from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAaDTxE8u7i4yHUEYqCWTZ_Z-pzfQ4DnO4",
    authDomain: "manetta-2023.firebaseapp.com",
    projectId: "manetta-2023"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app, "europe-west1");
